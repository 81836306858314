import React, { useState } from 'react';
import './Header.css';
import './../../../src/Project__Styles/fonts__project.css';
import icon__navbar__onPhone from './../../../src/images/icon__navbar__onPhone.png';
import Logo__company from './../../images/Logo__Company.png';
import { Outlet, Link } from "react-router-dom";

const Header = () => {
    const [Visible, setVisible] = useState(false);

    return (
        <div className={"Header__wrapper"}>
            <header className={"Header"}>
                <div className={"Header__logo"}>
                    <a href="/"><span>Дом'ОК</span><img src={Logo__company} /></a>
                </div>
                <div className="wrap__nav__items">
                    <Link className={"nav__item"} to="/">Главная</Link>
                    <Link className={"nav__item"} to="/Houses">Дома управляющей компании</Link>
                    <Link className={"nav__item"} to="/About">Информация о управляющей компании</Link>

                </div>

                <div className={"navi__item__icon"} onClick={() => setVisible(!Visible)} href="index.html">
                    <img src={icon__navbar__onPhone} alt="" />
                    <img src="" alt=""/>
                </div>
            </header>
            {(Visible &&
                <div className="wrap__navbar__onPhone" id={'onPhone'}>
                    <div className="wrap__nav__items__onPhone">
                        <div className={"nav__item icon__nav__item"} onClick={() => setVisible(!Visible)} href="">
                            <svg xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 96 960 960" width="26">
                                <path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                            </svg>
                        </div>
                        <hr/>
                        <Link className={"nav__item"} onClick={() => setVisible(!Visible)} to="/">Главная</Link>
                        <Link className={"nav__item"} onClick={() => setVisible(!Visible)} to="/Houses">Дома управляющей компании</Link>
                        <Link className={"nav__item"} onClick={() => setVisible(!Visible)} to="/About">Информация о управляющей компании</Link>

                    </div>
                </div>
            )}



        </div>
    );
};

export default Header;
