import React, { useState } from 'react';
import './Forma.css';
import Form from './../../images/form.png';
// import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com';

const Forma = () => {
//     const [isChecked, setIsChecked] = useState(false);
//     const captchaCheckboxChange = () => {
//         setIsChecked(!isChecked);
//     };



    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('domok_idserv', 'template_xjtux1g', e.target, 'NiLbkU9Yc0XIGL8ce')
            .then((result) => {
                alert("Форма отправлена, сайт перезагружен");
                window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
            }, (error) => {
                console.log(error.text);
            });
    }




    return (
        <>
            <main className="main" id="mainForm">
                <div className="wrap__form__obrs__and__contacts">
                    <div className="wrap-forma">

                        <form id="forma" data-wow-delay="0.3s" onSubmit={sendEmail}
                              className="wow animate__animated animate__fadeInUp">

                            <legend>Форма обратной связи</legend>
                            <br/>

                            <br/><br/>
                            <input type="text" className="form-control" name="name" placeholder="Тема обращения"
                                   required/>
                            <input type="text" className="form-control" name="surname" placeholder="ФИО заявителя"
                                   required/>
                            <input type="email" className="form-control" name="email" placeholder="Почта заявителя"
                                   required/>
                            <input type="text" className="form-control" name="phone" placeholder="Телефон"/>

                            <textarea className="form-control" name="text" rows="5"
                                      placeholder="Сообщение"></textarea>


                            <div className="subm">
                                <br/>


                                {/*<ReCAPTCHA*/}
                                {/*    sitekey="6LeleJQqAAAAABz1YucF1x0uW6bDlcl5GKoDVKf8"*/}
                                {/*    onChange={captchaCheckboxChange}*/}
                                {/*/>*/}
                                <br/>

                                <button type="submit" name="submit" className="submit send-form btn"> Отправить форму </button>

                            </div>

                            <br/>
                            <div className="wrapp-small">
                                <small>Нажимая кнопку «Отправить», вы даёте своё согласие на сбор и обработку персональных данных</small><br/>
                            </div>
                            <div className="status"></div>
                        </form>
                        <img id="imgform" src={Form} alt=""/>
                    </div>

                </div>


            </main>
        </>
    );
};

export default Forma;
