import './App.css';
import {Route, Routes, BrowserRouter} from 'react-router-dom'
import Main from './components/Main'

import AboutCompany from "./components/About__company/About__company";
import Header from "./components/Header/Header";
import House__management__company from "./components/house__management__company/house__management__company";
import Footer from "./components/Footer/Footer";



function App() {
  return (
    <div className="App">
      <BrowserRouter>

          <Header />
        <Routes>
            <Route path="/" element={<Main />}/>
            <Route path="/Houses" element={<House__management__company />}/>
            <Route path="/About" element={<AboutCompany />}/>




        </Routes>
          <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
