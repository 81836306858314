import React, {useState} from 'react';
import Houses from './../../images/houses.png';
// import emailjs from 'emailjs-com';
import './house__management__company.css';
// import ReCAPTCHA from "react-google-recaptcha";
import strelka from './../../images/arrow.png';
import {Link} from "react-router-dom";
import emailjs from "emailjs-com";
const HouseManagementCompany = () => {

    const [unVisible, setVisible] = useState(false);
    // const [isChecked, setIsChecked] = useState(false);
    // const captchaCheckboxChange = () => {
    //     setIsChecked(!isChecked);
    // };

    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('domok_idserv', 'template_pj6g869', e.target, 'NiLbkU9Yc0XIGL8ce')
            .then((result) => {
                alert("Форма отправлена, сайт перезагружен");
                window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div>


            <div className="wrap-banner-houseList">
                <h1>Список домов</h1>
            </div>
            <br/>


            <div className="wrap-houseList">


                <div className="card mb-3 wow animate__animated animate__fadeInUp">
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img src={Houses} className="img-fluid rounded-start"/>
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title">проезд Инженерный 3-й, д. 9</h5>
                                <p className="card-text"><small className="text-muted">Кадастровый номер:
                                    73:24:021104:1617</small></p>
                                <p className="card-text"><small className="text-muted">Год ввода в эксплуатацию:
                                    1997</small></p>
                                <p className="card-text"><small className="text-muted">Серия/Тип проекта здания:
                                    -</small></p>
                                <p className="card-text"><small className="text-muted">Количество этажей: 01</small></p>
                                <p className="card-text"><small className="text-muted">Состояние: Исправный</small></p>
                                <p className="card-text"><small className="text-muted">Управляющая организация: ООО
                                    "ДОМ' ОК"</small></p>
                                <p className="card-text"><small className="text-muted">Количество помещений
                                    (жилых/нежилых): 94/0</small></p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card mb-3 wow animate__animated animate__fadeInUp">
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img src={Houses} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title">проезд Инженерный 3-й, д. 10</h5>
                                <p className="card-text"><small className="text-muted">Кадастровый номер: -</small></p>
                                <p className="card-text"><small className="text-muted">Год ввода в эксплуатацию:
                                    2005</small></p>
                                <p className="card-text"><small className="text-muted">Серия/Тип проекта здания:
                                    -</small></p>
                                <p className="card-text"><small className="text-muted">Количество этажей: 5</small></p>
                                <p className="card-text"><small className="text-muted">Состояние: Исправный</small></p>
                                <p className="card-text"><small className="text-muted">Управляющая организация: ООО
                                    "ДОМ' ОК"</small></p>
                                <p className="card-text"><small className="text-muted">Количество помещений
                                    (жилых/нежилых): 50/0</small></p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card mb-3 wow animate__animated animate__fadeInUp">
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img src={Houses} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title">ул Ватутина, д. 26</h5>
                                <p className="card-text"><small className="text-muted">Кадастровый номер:
                                    7324040907</small></p>
                                <p className="card-text"><small className="text-muted">Год ввода в эксплуатацию:
                                    1962</small></p>
                                <p className="card-text"><small className="text-muted">Серия/Тип проекта здания:
                                    -</small></p>
                                <p className="card-text"><small className="text-muted">Количество этажей: 3</small></p>
                                <p className="card-text"><small className="text-muted">Состояние: Исправный</small></p>
                                <p className="card-text"><small className="text-muted">Управляющая организация: ООО
                                    "ДОМ' ОК"</small></p>
                                <p className="card-text"><small className="text-muted">Количество помещений
                                    (жилых/нежилых): 0/0</small></p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card mb-3 wow animate__animated animate__fadeInUp">
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img src={Houses} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title">ул Докучаева, д. 1</h5>
                                <p className="card-text"><small className="text-muted">Кадастровый номер:
                                    73:24:040801:78</small></p>
                                <p className="card-text"><small className="text-muted">Год ввода в эксплуатацию:
                                    1962</small></p>
                                <p className="card-text"><small className="text-muted">Серия/Тип проекта здания:
                                    -</small></p>
                                <p className="card-text"><small className="text-muted">Количество этажей: 2</small></p>
                                <p className="card-text"><small className="text-muted">Состояние: Ветхий</small></p>
                                <p className="card-text"><small className="text-muted">Управляющая организация: ООО
                                    "ДОМ' ОК"</small></p>
                                <p className="card-text"><small className="text-muted">Количество помещений
                                    (жилых/нежилых): -/-</small></p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card mb-3 wow animate__animated animate__fadeInUp">
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img src={Houses} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title">ул Радищева, д. 157</h5>
                                <p className="card-text"><small className="text-muted">Кадастровый номер:
                                    73:24:040814:1483</small></p>
                                <p className="card-text"><small className="text-muted">Год ввода в эксплуатацию:
                                    1959</small></p>
                                <p className="card-text"><small className="text-muted">Серия/Тип проекта здания:
                                    -</small></p>
                                <p className="card-text"><small className="text-muted">Количество этажей: 2</small></p>
                                <p className="card-text"><small className="text-muted">Состояние: Исправный</small></p>
                                <p className="card-text"><small className="text-muted">Управляющая организация: ООО
                                    "ДОМ' ОК"</small></p>
                                <p className="card-text"><small className="text-muted">Количество помещений
                                    (жилых/нежилых): -/-</small></p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card mb-3 wow animate__animated animate__fadeInUp">
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img src={Houses} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title">проезд Заводской, д. 31</h5>
                                <p className="card-text"><small className="text-muted">Кадастровый номер: -</small></p>
                                <p className="card-text"><small className="text-muted">Год ввода в эксплуатацию:
                                    2015</small></p>
                                <p className="card-text"><small className="text-muted">Серия/Тип проекта здания:
                                    -</small></p>
                                <p className="card-text"><small className="text-muted">Количество этажей: 11</small></p>
                                <p className="card-text"><small className="text-muted">Состояние: Исправный</small></p>
                                <p className="card-text"><small className="text-muted">Управляющая организация: ООО
                                    "ДОМ' ОК"</small></p>
                                <p className="card-text"><small className="text-muted">Количество помещений
                                    (жилых/нежилых): -/-</small></p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card mb-3 wow animate__animated animate__fadeInUp">
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img src={Houses} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title">ул Циолковского, д. 1/82</h5>
                                <p className="card-text"><small className="text-muted">Кадастровый номер: -</small></p>
                                <p className="card-text"><small className="text-muted">Год ввода в эксплуатацию:
                                    1982</small></p>
                                <p className="card-text"><small className="text-muted">Серия/Тип проекта здания:
                                    -</small></p>
                                <p className="card-text"><small className="text-muted">Количество этажей: 9</small></p>
                                <p className="card-text"><small className="text-muted">Состояние: Исправный</small></p>
                                <p className="card-text"><small className="text-muted">Управляющая организация: ООО
                                    "ДОМ' ОК"</small></p>
                                <p className="card-text"><small className="text-muted">Количество помещений
                                    (жилых/нежилых): 60/0</small></p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card mb-3 wow animate__animated animate__fadeInUp">
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img src={Houses} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-8">
                            <div className="card-body" id="card-body-last card-body">
                                <h5 className="card-title">Cледующий дом может быть вашим</h5>
                                <br/><br/><br/><br/>
                                <div onClick={() => setVisible(!unVisible)} className="btn" type="button">Что нужно сделать?</div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>



            {(unVisible &&
            <div className="wrap__form__perehod-doma">
                <div className="wrap-text-form">
                    <div className="wrap-info">
                        <h1>Как перейти к нам?</h1>
                        <h2>Не так сложно, как может показаться. Достаточно оставить заявку кликнув по кнопке ниже,
                            наш менеджер свяжется с Вами. Совместно обследуем дом, составим план работ, предложим
                            размер платы и если все всех устроит поможем провести собрание по переходу в нашу
                            УК.</h2>

                    </div>
                    <img src={strelka} alt=""/>
                </div>
                <form id="fade" className="form" action="../../phpFiles/house-send.php" method="POST" onSubmit={sendEmail}>
                    <br/>


                    <input type="text" className="form-control" name="name" placeholder="Адрес" required
                    />
                    <input type="text" className="form-control" name="surname" placeholder="ФИО заявителя" required
                        />
                        <input type="email" className="form-control" name="email" placeholder="Почта заявителя" required
                        />
                        <input type="text" id="last" className="form-control" name="phone" placeholder="Телефон"
                        />


                    <div className="subm">
                        <br/><br/><br/>


                        {/*<ReCAPTCHA*/}
                        {/*    sitekey="6LeleJQqAAAAABz1YucF1x0uW6bDlcl5GKoDVKf8"*/}
                        {/*    onChange={captchaCheckboxChange}*/}
                        {/*/>*/}
                        {/*<br/>*/}

                        <button type="submit" name="submit" className="submit send-form btn"
                                > Отправить форму
                        </button>

                    </div>


                    <br/>
                    <small>Нажимая кнопку «Отправить», вы даёте своё
                        согласие на сбор и обработку персональных данных</small><br/>


                </form>
            </div>)}
        </div>
    );
};

export default HouseManagementCompany;